<template>
  <div class="game-selector">
    <select v-model="selectedGame" @change="notifyGameChange" class="game-select">
      <option v-for="game in games" :key="game" :value="game">{{ game }}</option>
    </select>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  emits: ['game-changed'],
  setup(props, { emit }) {
    const selectedGame = ref('TapSwap');
    const games = ['TapSwap', 'Blum', 'MemeFi'];

    const notifyGameChange = () => {
      emit('game-changed', selectedGame.value);
    };

    return {
      selectedGame,
      games,
      notifyGameChange
    };
  }
};
</script>

<style scoped>
.game-selector {
  display: flex;
  align-items: center;
}

.game-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
