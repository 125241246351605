<template>
  <div class="button-container">
    <button @click="subscribeToNotifications" class="button">{{ $t('message.subscribeText') }}</button>
  </div>
</template>

<script>
export default {
  emits: ['updateText'],
  methods: {
    subscribeToNotifications() {
      this.$emit('updateText');
      window.open('https://t.me/TapGameHelper_bot?start=fromSite', '_blank');
    }

  }
}
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  padding: 2.5rem;
  position: absolute;
  top: 1rem;
  right: 0;
}

.button {
  padding: 0.5rem; /* Добавляем отступы для кнопок */
  border: none;
  border-radius: 4px;
  background-color: #9e9e9e; /* Мягкий серый цвет */
  color: #ffffff; /* Белый текст */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 150px; /* Фиксированная ширина для всех кнопок */
  height: 40px; /* Фиксированная высота для всех кнопок */
  text-align: center; /* Выравнивание текста по центру */
  font-size: 0.75rem; /* Уменьшенный размер шрифта */
  display: flex; /* Используем flexbox для центрирования текста */
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .button {
    width: 150px;
    height: 40px;
  }
}

/* Large screens (desktops) */
@media (min-width: 1024px) {
  .button {
    font-size: 1.5rem;
    width: 340px;
    height: 100px;
  }
}

.button:hover {
  background-color: #757575; /* Более темный серый цвет при наведении */
  box-shadow: none; /* Убираем тень при наведении */
}

.button:focus {
  outline: none; /* Убираем стандартный фокусный контур */
  box-shadow: none; /* Убираем тень при фокусе */
}
</style>
