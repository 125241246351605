import { createI18n } from 'vue-i18n';


const messages = {
  en: {
    message: {
      topTextFull: 'All codes on tapgame.lol — easy and convenient! Subscribe to stay updated',
      topTextAfterSubscribe: 'All codes on tapgame.lol — easy and convenient!',
      bottomText: 'Human-like actions script for automatic video viewing and city building will be available soon for your browser.',
      subscribeText: '🔔 me of new answers',
      searchButton: 'Search',
      awaitCodeStartText: 'Awaiting launch of this game.',
      awaitCodeMiddleText: 'Subscribe to our Telegram bot',
      awaitCodeEndText: 'to get notified when the game is available.',
      mainSearchTitle: 'video codes answers',
      typeToSearch: 'Write part of the description...',
      noSearchResult: "No results were found based on your description. Please try a different query",
      copyResult: "was copied. Please enter the code into the game"
    }
  },
  ru: {
    message: {
      topTextFull: 'Все коды на tapgame.lol — легко и удобно! Подписывайтесь, чтобы не пропустить обновления',
      topTextAfterSubscribe: 'Все коды на tapgame.lol — легко и удобно!',
      bottomText: 'Скрипт для автоматического просмотра видео и развития города скоро будет доступен для вашего браузера.',
      subscribeText: '🔔 Уведомлять о новых кодах',
      searchButton: 'Поиск',
      waitCodeStartText: 'Ожидание запуска этой игры.',
      waitCodeMiddleText: 'Подпишитесь на нашего Telegram-бота',
      waitCodeEndText: 'чтобы получать уведомления, когда игра станет доступна.',
      mainSearchTitle: 'видео коды ответы',
      typeToSearch: 'Введите часть описания...',
      noSearchResult: "Результатов по вашему описанию не найдено. Попробуйте другой запрос",
      copyResult: "был скопирован. Пожалуйста, введите код в игру"
    }
  },
  uk: {
    message: {
      topTextFull: 'Усі коди на tapgame.lol — легко та зручно! Підписуйтесь, щоб не пропустити оновлення',
      topTextAfterSubscribe: 'Усі коди на tapgame.lol — легко та зручно!',
      bottomText: 'Скрипт для автоматичного перегляду відео та створення міста незабаром буде доступний для вашого браузера.',
      subscribeText: '🔔 Повідомляти про нові відповіді',
      searchButton: 'Пошук',
      waitCodeStartText: 'Очікування запуску цієї гри.',
      waitCodeMiddleText: 'Підпишіться на нашого Telegram-бота',
      waitCodeEndText: 'щоб отримувати сповіщення, коли гра стане доступною.',
      mainSearchTitle: 'коди до відео',
      typeToSearch: 'Введіть частину опису відео...',
      noSearchResult: "Результатів за вашим описом не знайдено. Спробуйте інший запит",
      copyResult: "був скопійований. Будь ласка, введіть код у гру"
    }
  }
};

const userLanguage = navigator.language || 'en';


const getDefaultLocale = (lang) => {
  if (lang.startsWith('ru')) {
    return 'ru';
  }
  if (lang.startsWith('uk')) {
    return 'uk';
  }
  return 'en';
};

const defaultLocale = getDefaultLocale(userLanguage);
console.log(defaultLocale);


const i18n_t = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});

export default i18n_t;
