<template>
  <div class="results-container">

      <template v-if="results.length > 0">
        <table class="results-table">
          <tbody>
            <tr v-for="(answer, index) in results" :key="index">
              <td @click="copyToClipboard(answer.key)" class="key-column">{{ answer.key }}</td>
              <td>{{ answer.title }}</td>
            </tr>
          </tbody>
        </table>
      </template>

    <div v-if="copyMessage" class="copy-message">{{copyKey}} {{ $t(copyMessage) }}</div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    results: {
      type: Array,
      required: true
    },
    searchPerformed: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const copyMessage = ref('');
    const copyKey = ref('');

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        copyMessage.value = 'message.copyResult'
        copyKey.value = text;

        setTimeout(() => {
          copyMessage.value = '';
          copyKey.value = '';
        }, 3000);
      }).catch(err => {
        console.error("error copy data at buffer", err);
      });
    };


    watch(() => props.results, (newResults) => {
      if (props.searchPerformed && newResults.length === 0) {

        copyMessage.value = `message.noSearchResult`;

        setTimeout(() => {
          copyMessage.value = '';
        }, 3000);

      }
    });


    return {
      copyMessage,
      copyToClipboard,
      copyKey
    };
  }
};
</script>

<style scoped>
.results-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem auto; /* Центрирование контейнера на странице */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  overflow: hidden; /* Убираем внешнюю прокрутку */
}

.results-table thead {
  display: none; /* Скрываем заголовки на мобильных устройствах */
}

.results-table tbody {
  display: block;
  max-height: 300px; /* Высота с прокруткой */
  overflow-y: auto;
}

.results-table tr {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
}

.results-table td, .results-table th {
  padding: 0.75rem;
  text-align: left;
  display: block;
  width: 100%; /* Ширина ячеек на мобильных устройствах */
}

.key-column {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.key-column:hover {
  color: #0056b3;
}

/* Стиль для сообщения об успешном копировании */
.copy-message {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

/* Медиа-запросы для адаптации на различных экранах */
@media (min-width: 600px) {
  .results-table thead {
    display: table-header-group; /* Отображаем заголовки на более широких экранах */
  }

  .results-table tr {
    display: table-row;
    flex-direction: row;
  }

  .results-table td {
    display: table-cell;
    width: auto;
  }
}

.no-results {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
</style>
