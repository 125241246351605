<template>
  <div id="app" class="container">
    <NotificationBookmarkButtons @updateText="updateTopText"/>
    <div class="header">
      <GameSelector @game-changed="updateGame" />
      <h1 class="title">{{ selectedGame }} {{ $t('message.mainSearchTitle') }}</h1>
    </div>
    <SearchBar :selected-game="selectedGame" :is-disabled="!isTapSwapSelected" @fetch-data="updateResults" />
    <ResultsList :results="results" :searchPerformed="searchPerformed" />
    <anonsTextData :top-text="topText" />
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import ResultsList from './components/ResultsList.vue';
import GameSelector from './components/GameSelector.vue';
import NotificationBookmarkButtons from './components/NotificationBookmarkButtons.vue';
import anonsTextData from './components/anonsTextData.vue';
import { ref, computed } from 'vue';


export default {
  mounted() {
    document.title = 'game answers';
  },
  components: {
    SearchBar,
    ResultsList,
    GameSelector,
    NotificationBookmarkButtons,
    anonsTextData
  },
  setup() {
    const results = ref([]);
    const searchPerformed = ref(false);
    const selectedGame = ref('TapSwap');

    const isTapSwapSelected = computed(() => selectedGame.value === 'TapSwap');

    const topText = ref('message.topTextFull');
    const updateTopText = () => {
      topText.value = 'message.topTextAfterSubscribe';
    };

    const updateGame = (game) => {
      selectedGame.value = game;
      results.value = [];
      searchPerformed.value = false;
    };

    const updateResults = (data) => {
      console.log("at updateResults", data)
      results.value = data;
      searchPerformed.value = true;
    };

    return {
      results,
      selectedGame,
      updateGame,
      updateResults,
      isTapSwapSelected,
      searchPerformed,
      topText,
      updateTopText
    };
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Центрирование по вертикали */
  padding: 1rem;
  background: linear-gradient(to bottom, #B88E48, #F3DFAB, #B88E48);
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px; /* Ограничение ширины */
  margin-bottom: 2rem;
}

.title {
  margin: 0;
  text-align: center;
  font-size: 2.5rem; /* Размер шрифта заголовка */
  font-weight: bold; /* Полужирный шрифт */
  color: #333; /* Цвет текста */
}
</style>
