<template>
  <div class="search-bar">
  <input
    type="text"
    :disabled="isDisabled"
    v-model="query"
    class="search-input"
    :placeholder="$t('message.typeToSearch')"
  />
    <button @click="fetchData" :disabled="isDisabled" class="search-button">{{ $t('message.searchButton') }}</button>
  </div>
  <div v-if="isDisabled" class="disabled-message">
      {{ $t('message.waitCodeStartText') }}
      <a href="https://t.me/TapGameHelper_bot?start=fromSite" target="_blank" class="subscribe-link">{{ $t('message.waitCodeMiddleText') }}</a>
      {{ $t('message.waitCodeEndText') }}
  </div>

</template>

<script>
import axios from 'axios';
import { ref, watch } from 'vue';

export default {
  props: {
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedGame: {
      type: String,
      required: true
    }
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const query = ref('');

    const fetchData = () => {
      if (!props.isDisabled && query.value.trim()) {
        axios
          .get(`/api/${props.selectedGame.toLowerCase()}/answers/title?title=${query.value}`)
          .then(response => {
            emit('fetch-data', response.data.answers);
          })
          .catch(error => {
            console.error('Error fetching data', error);
          });
      }
    };

    watch(() => props.selectedGame, (newVal) => {
      console.log(`Selected game changed to: ${newVal}`);
      query.value = '';
    });

    return {
      query,
      fetchData
    };
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.search-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
}

.search-input:disabled,
.search-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.search-button:disabled {
  color: #888;
  background-color: #ccc;
}

.disabled-message {
  color: #888;
  font-size: 0.875rem; /* Smaller font size */
  margin-top: 0.5rem; /* Spacing above the message */
  text-align: center;
}

.subscribe-link {
  color: #007bff;
  text-decoration: underline;
}

.subscribe-link:hover {
  color: #0056b3;
}
</style>
