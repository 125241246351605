<template>
  <div>
    <div class="text-container-top">
      {{ $t(topText) }}
    </div>

    <div class="text-container">
      {{ $t('message.bottomText') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topText: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.text-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(248, 249, 250, 0.8); /* Полупрозрачный фон */
  padding: 0.75rem; /* Уменьшен отступ */
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem; /* Немного уменьшен размер шрифта */
  backdrop-filter: blur(8px); /* Эффект размытия фона */
  left: 0; /* Добавлено для выравнивания по левому краю */
}

.text-container-top {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(248, 249, 250, 0.8); /* Полупрозрачный фон */
  padding: 0.45rem; /* Уменьшен отступ */
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem; /* Еще меньше размер шрифта */
  backdrop-filter: blur(8px); /* Эффект размытия фона */
  left: 0; /* Добавлено для выравнивания по левому краю */
}

</style>
